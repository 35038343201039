









































import { Component, Vue } from "vue-property-decorator";
// @ is an alias to /src

@Component({})
export default class FooterBlock extends Vue {}
